<template>
    <base-dialog
        view-only
        max-width="900"
        :loading="loading"
        :title="title"
        :value="open"
        @input="(v) => $emit('input', v)"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="primary"
                v-bind="attrs"
                :disabled="!selectionDisabled"
                block
                data-cy="open-selection"
                v-on="on"
            >
                Set Up API
            </v-btn>
        </template>
        <template v-slot:content>
            <v-card-text data-cy="api-selection-dialog">
                <v-row v-if="!loading" justify="center">
                    <v-col v-for="p in providers" :key="p.id" cols="3">
                        <provider-card :provider="p" @click="selected(p)" />
                    </v-col>
                </v-row>
            </v-card-text>
        </template>
    </base-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseDialog from '@/components/modal/BaseDialog'
import ProviderCard from './ProviderCard'
import { ROLE_SETUP_SIS_IMPORTS_API_EDIT } from '@/helpers/security/roles'

export default {
    name: 'ApiSelectionDialog',
    components: { BaseDialog, ProviderCard },
    model: { prop: 'provider', event: 'selected' },
    props: {
        provider: {
            type: Object,
            default: null,
        },
        title: {
            type: String,
            default: 'Set Up SIS Import | Select API Provider',
        },
        open: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            sisImportProviders: 'sisSystem/getItems',
            districtId: 'getDistrictId',
            lea: 'getDistrictLea',
            loading: 'sisSystem/getFetching',
            hasRole: 'auth/hasRole',
            sisApiConfig: 'sisApiConfig/getItems',
        }),
        selectionDisabled() {
            return this.hasRole(ROLE_SETUP_SIS_IMPORTS_API_EDIT)
        },
        providers() {
            return this.sisImportProviders
                .filter((p) => p.importAvailable)
                .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1))
        },
    },
    async mounted() {
        // check if the sisImportProviders have already been loaded.
        if (!this.sisImportProviders.length) {
            this.getSisImportProviders()
        }
    },
    methods: {
        ...mapActions({
            getSisImportProviders: 'sisSystem/get',
        }),
        selected(p) {
            this.$emit('selected', p)
            this.$emit('input', false)
        },
    },
}
</script>
