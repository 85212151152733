<template>
    <div class="d-flex justify-center align-center flex-column fill-height">
        <v-row align="center" align-content="center" justify="center">
            <v-col cols="6">
                <v-img src="@/assets/sis_api_setup.svg" alt="API Setup Image" contain />
            </v-col>

            <!-- This needs to be 12 otherwise it won't stack, and widths will be weird -->
            <v-col cols="12">
                <h1 class="text-center">API Set Up</h1>
                <p class="text-center">
                    There is no API currently set up. <br />
                    Please click the "Set Up API" button to begin importing data from the SIS API.
                </p>
            </v-col>
            <v-col cols="3">
                <api-selection-dialog
                    v-model="provider"
                    :open="selectDialog"
                    @input="(v) => (selectDialog = v)"
                    @selected="(v) => (configDialog = !!v)"
                />
                <api-config-dialog
                    v-if="provider"
                    :key="provider.id"
                    v-model="configDialog"
                    :provider="provider"
                    @input="(v) => (configDialog = v)"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ApiSelectionDialog from './ApiSelectionDialog'
import ApiConfigDialog from './ApiConfigDialog'

export default {
    components: {
        ApiSelectionDialog,
        ApiConfigDialog,
    },
    data: () => ({
        provider: null,
        selectDialog: false,
        configDialog: false,
    }),
}
</script>

<style></style>
