<template>
    <v-card v-bind="$attrs" class="provider-card" v-on="$listeners">
        <v-card-text class="text-center">
            <v-img class="icon" :src="img" max-height="60px" contain />
            <h4 class="my-2 black--text">{{ provider.name }}</h4>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'ProviderCard',
    props: {
        provider: {
            type: Object,
            required: true,
        },
    },
    computed: {
        img() {
            try {
                return require(`@/assets/sis-api-logos/Logo-${this.provider.name}.png`)
            } catch (e) {
                // default to Plug icon.
                return require(`@/assets/sis-api-logos/Logo-Plug.png`)
            }
        },
    },
}
</script>

<style scoped>
.provider-card .icon {
    height: 60px;
}
</style>
