<template>
    <v-skeleton-loader
        v-if="fetching || systemFetching"
        v-show="fetching || systemFetching"
        :loading="fetching || systemFetching"
        type="image, heading, sentances@2, heading, sentances, button"
        class="loader"
    />
    <new-api-configuration v-else-if="config === null || provider === null" />
    <div v-else>
        <child-layout :title="apiName" class="sis-api__overview-layout">
            <template v-slot:actions>
                <api-config-dialog v-model="configOpen" :provider="provider">
                    <template v-slot:default="{ on, attrs }">
                        <v-icon :disabled="!canEditApi" v-bind="attrs" v-on="on">
                            {{ 'fal fa-pencil' }}
                        </v-icon>
                    </template>
                </api-config-dialog>
            </template>
            <v-row class="my-5" align="center">
                <v-col cols="auto">
                    <v-img
                        :src="providerLogoSrc"
                        :alt="`${provider.name} Logo`"
                        max-height="60"
                        contain
                    />
                </v-col>
                <overview-detail :label="`${provider.name} Base URL`" :value="config.sisApiUrl" />
                <overview-detail label="Consumer ID" :value="config.sisId" />
                <overview-detail label="Certificate" obfuscate :value="config.sisApiKey" />
                <overview-detail label="Secret" obfuscate :value="config.sisApiSecret" />
                <overview-detail
                    label="Daily SIS Sync Time"
                    :value="config.sisSyncTime | twentyFourHourTo12HourTime"
                />
            </v-row>
        </child-layout>
        <sync-now v-if="canRequestDataPull" />
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ApiConfigDialog from './ApiConfigDialog'
import ChildLayout from '@/components/layout/ChildLayout'
import { DateTime } from 'luxon'
import { ROLE_SETUP_SIS_IMPORTS_API_EDIT, ROLE_ILLUMINATE_EMPLOYEE } from '@/helpers/security/roles'

import OverviewDetail from './OverviewDetail.vue'
import NewApiConfiguration from './NewApiConfiguration.vue'
import SyncNow from './SyncNow'

export default {
    name: 'SisImportProviderOverview',
    components: {
        ChildLayout,
        ApiConfigDialog,
        OverviewDetail,
        NewApiConfiguration,
        SyncNow,
    },
    filters: {
        twentyFourHourTo12HourTime: function (time) {
            if (time) {
                return DateTime.fromFormat(time, 'HH:mm').toFormat("hh:mm a '(EST)'")
            }
            return '--'
        },
    },

    data: function () {
        return {
            componentMounted: false,
            configOpen: false,
        }
    },
    computed: {
        ...mapGetters({
            districtId: 'getDistrictId',
            districtLea: 'getDistrictLea',
            sisImportProviders: 'sisSystem/getItemsById',
            sisConfigs: 'sisApiConfig/getItemsById',
            hasRole: 'auth/hasRole',
            fetching: 'sisApiConfig/getFetching',
            systemFetching: 'sisSystem/getFetching',
        }),
        config() {
            return this.sisConfigs[this.districtId] ?? null
        },
        provider() {
            if (this.config) {
                return this.sisImportProviders[parseInt(this.config.cfgSisSystemId)] ?? null
            }

            return null
        },
        apiName() {
            if (this.provider) {
                return `${this.provider?.name} API`
            }
            return ''
        },
        providerLogoSrc() {
            if (this.provider?.name !== null && this.provider?.name !== undefined) {
                const images = require.context('@/assets/sis-api-logos/', false, /\.png$/)

                try {
                    return images('./Logo-' + this.provider.name + '.png')
                } catch {
                    return images('./Logo-Plug.png')
                }
            } else {
                return ''
            }
        },
        canEditApi() {
            return this.hasRole(ROLE_SETUP_SIS_IMPORTS_API_EDIT)
        },
        canRequestDataPull() {
            return this.hasRole(ROLE_ILLUMINATE_EMPLOYEE) && this.canEditApi
        },
    },
    async mounted() {
        this.getConfig({ id: this.districtId, lea: this.districtLea })
        if (!this.sisImportProviders?.length) {
            this.getSisImportProviders()
        }
    },
    methods: {
        ...mapActions({
            getConfig: 'sisApiConfig/get',
            getSisImportProviders: 'sisSystem/get',
        }),
    },
}
</script>
<style lang="scss" scoped>
.v-skeleton-loader {
    padding-bottom: 35px;

    &::v-deep .v-skeleton-loader__sentences:not(:last-of-type) {
        padding-bottom: 35px;
    }
}

.loader::v-deep div {
    margin-bottom: 10px;
}

.sis-api__overview-layout {
    .col:first-of-type {
        padding-right: 0;
    }
}
</style>
