<template>
    <v-col>
        <div class="child-layout__label">
            {{ label }}
        </div>
        <div class="child-layout__info">
            {{ obfuscate && value && value.length > 1 ? '*********' : value }}
        </div>
    </v-col>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: false,
            default: '',
        },
        obfuscate: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style></style>
