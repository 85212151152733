<template>
    <v-form v-model="valid">
        <v-row class="pt-8">
            <v-col cols="auto">
                <alert-message
                    v-model="showImportSuccessAlert"
                    style="margin: auto"
                    color="blue"
                    dismissible
                    rounded="0"
                    transition="slide-y-transition"
                >
                    <strong>The import has been initiated.</strong>
                    Please allow 10 minutes to complete the import process.<br />Go to
                    <router-link class="sis-imports__alert-link" :to="{ name: 'import-logs' }">
                        Import Logs</router-link
                    >
                    for results!
                </alert-message>
            </v-col>
        </v-row>
        <v-row>
            <v-col align-self="end" cols="4">
                <h4 class="text-primary">Choose Data Pull Date Range</h4>
            </v-col>
        </v-row>
        <v-row align="center" dense>
            <v-col cols="2">
                <label class="v-label theme--light">
                    <span class="required">From School Year</span>
                </label>
            </v-col>
            <v-col cols="2">
                <v-select
                    ref="fromSchoolYear"
                    v-model="fromSchoolYear"
                    :items="schoolYears"
                    :rules="rules.fromSchoolYear"
                    dense
                    outlined
                    hide-details="auto"
                    prepend-inner-icon="fal fa-calendar-alt"
                    class="sis-imports__year-select"
                >
                </v-select>
            </v-col>
        </v-row>
        <v-row align="center" dense class="mt-4">
            <v-col cols="2">
                <label class="v-label theme--light">
                    <span class="required">To School Year</span>
                </label>
            </v-col>
            <v-col cols="2">
                <v-select
                    ref="toSchoolYear"
                    v-model="toSchoolYear"
                    :items="schoolYears"
                    :rules="rules.toSchoolYear"
                    dense
                    outlined
                    hide-details="auto"
                    prepend-inner-icon="fal fa-calendar-alt"
                    class="sis-imports__year-select"
                ></v-select>
            </v-col>
        </v-row>
        <v-row class="pt-8">
            <v-col cols="4">
                <h4 class="text-primary">Send Data Pull Notifications</h4>
            </v-col>
        </v-row>
        <v-row justify="start" align="center">
            <v-col cols="2">
                <label class="v-label theme--light">Email Notifications</label>
            </v-col>
            <v-col cols="auto">
                <v-switch
                    v-model="importNowData.emailNotification"
                    class="sis-imports__switch"
                    inset
                    hide-details
                    :false-value="null"
                    :true-value="user.ssoUser.email"
                />
            </v-col>
        </v-row>
        <v-row align="center">
            <v-col cols="2">
                <v-btn
                    class="primary v-btn--active"
                    :disabled="!valid || sites.length === 0"
                    @click="importNow"
                >
                    <v-icon left small>fal fa-cloud-download</v-icon>
                    Import Now
                </v-btn>
            </v-col>
            <v-col cols="auto">
                <alert-message
                    v-if="sites.length === 0 && !sitesFetching"
                    type="warning"
                    style="margin: auto"
                    rounded
                >
                    <strong>Site file is missing!</strong>
                    Upload the site file under Site Management &rarr;
                    <router-link :to="{ name: 'site-overview' }" class="sis-imports__alert-link">
                        Add Site(s).
                    </router-link>
                </alert-message>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { getYearTerms } from '@/helpers/shared'
import { currentSchoolYear } from '@/helpers/form/formatting'
import sisSyncNow from '@/mixins/SisImports/syncNow'
import { errorSnackbar } from '@/helpers/snackbar'
import { ROLE_SETUP_SIS_IMPORTS_API_EDIT } from '@/helpers/security/roles'
import AlertMessage from '@/components/alert/AlertMessage'

export default {
    components: {
        AlertMessage,
    },
    mixins: [sisSyncNow],
    data: function () {
        return {
            showImportSuccessAlert: false,

            valid: false,
            importNowData: {
                fromSchoolYear: null,
                toSchoolYear: null,
                emailNotification: null,
            },
            rules: {
                fromSchoolYear: [
                    (fromSchoolYear) =>
                        !!fromSchoolYear || "Must provide a value from 'From School Year'",
                    (fromSchoolYear) =>
                        !fromSchoolYear ||
                        !this.toSchoolYear ||
                        fromSchoolYear <= this.toSchoolYear ||
                        'Value must not be after To School Year',
                ],
                toSchoolYear: [
                    (toSchoolYear) =>
                        !!toSchoolYear || "Must provide a value from 'To School Year'",
                    (toSchoolYear) =>
                        !toSchoolYear ||
                        !this.fromSchoolYear ||
                        toSchoolYear >= this.fromSchoolYear ||
                        'Value must not be before From School Year',
                ],
            },
        }
    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            user: 'auth/getUser',
            sites: 'site/getItems',
            sitesFetching: 'site/getFetching',
            districtLea: 'getDistrictLea',
        }),
        schoolYears() {
            return getYearTerms(currentSchoolYear(), 12)
        },
        toSchoolYear: {
            get: function () {
                return this.importNowData.toSchoolYear
            },
            set: function (value) {
                this.importNowData.toSchoolYear = value
                this.$refs.fromSchoolYear.resetValidation()
            },
        },
        fromSchoolYear: {
            get: function () {
                return this.importNowData.fromSchoolYear
            },
            set: function (value) {
                this.importNowData.fromSchoolYear = value
                this.$refs.toSchoolYear.resetValidation()
            },
        },

        canEditApi() {
            return this.hasRole(ROLE_SETUP_SIS_IMPORTS_API_EDIT)
        },
    },
    mounted() {
        this.getSites({ lea: this.districtLea })
    },
    methods: {
        ...mapActions({
            sisSyncNow: 'sisImports/sisSyncNow',
            getSites: 'site/get',
        }),
        async importNow() {
            const { fromSchoolYear, toSchoolYear, emailNotification } = this.importNowData
            const payload = {
                lea: this.districtLea,
                data: {
                    schoolYearStart: Number(fromSchoolYear?.substr(0, 4)),
                    schoolYearEnd: Number(toSchoolYear?.substr(0, 4)),
                },
            }

            if (emailNotification !== null) {
                payload.data = { ...payload.data, emailNotification }
            }
            const resp = await this.sisSyncNow(payload)
            if (!resp.errors) {
                this.showImportSuccessAlert = true
            } else {
                const syncErrors = resp.errors.map((e) => e.message).join(', ')
                await errorSnackbar({
                    subtext: `An error occurred while attempting to sync: ${syncErrors}`,
                })
            }

            return 'N/A'
        },
    },
}
</script>

<style scoped>
.sis-imports__switch {
    margin-top: 0;
    padding-top: 0;
}

.sis-imports__year-select {
    min-width: 200px;
}

.sis-imports__alert-link {
    text-decoration: underline;
    color: inherit;
    font-weight: bold;
}
</style>
